
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import $ from 'jquery';
import Browser from '@/support/browser';
import PlayerApi from '@/support/playerApi';
import PlayerUtil from '@/support/playerUtil';
import { ServerDataModule } from '@/store/serverData';
import Util from '@/support/utility';
import { IWizardManager, IVideo } from '@/interfaces';




@Component
export default class VideoAdminComponent extends Vue {
    options = {
    };

    get serverData() {
        return ServerDataModule.serverData;
    }
   
    @Prop({ default: null })
    wizard: IWizardManager;

    @Prop({ default: <any>{} })
    parent: any;

    @Prop({ default: <any>{} })
    video: IVideo;

    busy = false;
    creatingCaptions = false;

    created() {
        Debug.setDebugModule('VideoAdmin', this);
    }

    mounted() {
    }

    showExpired() {
        this.done();
    }

    showError(error: string, error2: string = null) {
        this.done();
    }

    done() {
        this.wizard.back();
    }

    cancel() {
        this.wizard.back();
    }

    copyToClipboard(value: string, message: string = null) {
        Browser.copyToClipboard(value, message);
    }

    async recreateCaptions(video) {
        try {
            return await PlayerApi.recreateCaptions(video, true);
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error("recreateCaptions exception", video.studio, video.id, video, message);
            Util.showToast('Failed to create captions. ' + message, true);
            return null;
        }
    }

    async downloadCaptions(video: IVideo, event: any = null) {
        if (video.disableDownload) {
            Debug.log('downloadCaptions disabled', video.studio, video.id, event);
            return false;
        }

        Debug.log('downloadCaptions starting', video.studio, video.id, event);
        this.busy = true;
        this.creatingCaptions = true;

        Util.showToast('Processing video. Download link will be provided when ready.<br/><br/>This can take 20 minutes or more.<br/><br/>Please wait.', false, '5000');

        let result = await this.recreateCaptions(video);

        Debug.log('downloadCaptions started', video.studio, video.id, result);

        if (result.completed) {
            this.completeCaptionsDownload(video, result.succeeded);
            return true;
        }

        let interval = setInterval(() => {
            this.waitForBackgroundJob(video, result, interval);
        }, 10000);

        return false;
    }

    async waitForBackgroundJob(video: IVideo, jobInfo: SUR.BackgroundJobDto, interval) {
        try {
            let result = await PlayerApi.checkBackgroundJob(jobInfo);
            if (result.completed) {
                Debug.log('waitForBackgroundJob', video, jobInfo);
            }

            if (result.completed) {
                clearInterval(interval);
                this.completeCaptionsDownload(video, result.succeeded);
                return true;
            }

            // Still running
            video.captionsProgress = Math.round(result.progress);
            return false;
        } catch (err) {
            clearInterval(interval);
            this.completeCaptionsDownload(video, false);

            let message = err.message || 'ERROR';
            Debug.error('waitForBackgroundJob', message, video);

            return false;
        }
    }

    async completeCaptionsDownload(video: IVideo, result: boolean) {
        Debug.log('completeCaptionsDownload', video, result);

        try {
            await this.parent.refreshPage(true);

            if (!result) {
                Util.showToast('Failed to recreate captions for ' + video.title, true);
            } else {
                Util.showToast('Created captions for ' + video.title);
            }

        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error("completeCaptionsDownload exception", video.studio, video.id, video, message);
            Util.showToast('Failed to completeCaptionsDownload. ' + message, true);
            return null;
        } finally {
            this.busy = false;
            this.creatingCaptions = false;
        }
    }

    renameAsset() {
        this.wizard.back();
        this.wizard.navigateTo('renameAsset');
    }

    async resetAsset(video: IVideo, event: any = null) {
        Debug.log('resetAsset starting', video.studio, video.id);

        try {
            let response = await PlayerApi.resetAsset(video);

            Debug.log("resetAsset SUCCESS", video.studio, video.id, video, response);
            await this.parent.refreshPage(true);

            Util.showToast('Reset video information for ' + video.title);
            return true;
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error("resetAsset FAILED", video.studio, video.id, video, message);
            Util.showToast('Failed to recreate captions ' + video.title, true);
            return null;
        }
    }

    deleteVideo(video, event: any = null) {
        this.wizard.back();
        this.wizard.navigateTo('deleteVideo');
    }
}
